/* ================================================================================== 
									Fonts
===================================================================================== */

@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700&display=swap');

/* ================================================================================== 
										Reset Css
===================================================================================== */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
    font-family: 'Quicksand', sans-serif;
    color: #9c9c9c;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
}
ul {
	list-style: none;
}
a {
	color: inherit;
	text-decoration: none;
	font-family: 'Quicksand', sans-serif;
}
a:hover {
	text-decoration: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
button {
	outline: none;
	cursor: pointer;
}
button:focus {
	outline: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
* {
	margin:0;
	padding: 0;
}
a:focus {
	text-decoration: none;
	outline: none;
}
button {
	outline: none;
}
input {
	outline: none;
}
textarea {
	outline: none;
}
section {
	position: relative;
}
img {
	display: inline-block;
	margin-bottom: -6px;
}
p {
	font-size: 16px;
	line-height: 30px;
	color: #777777;
	font-family: 'Quicksand', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700!important;
	font-family: 'Quicksand', sans-serif;
}
a:hover,
a:focus {
	color: inherit;
	text-decoration: none!important;
}

.container {
	max-width: 1200px;
}
.clearfix {
	clear: both;
}
.sec-title > h2 {
    font-weight: 700;
}

/* ===================== BROWSER COMPATABILITY ======================*/


nav ul li a,
nav ul ul,
nav ul ul li,
.mobile-menu,
.mobile-menu ul li a:before,
.btn-default,
.feature-col:before,
.ftz-btn,
.case-info > h3,
.slick-dots li button,
.team-col:before,
.social-links,
.social-links li a,
.partner-col > a img,
.get-text > a,
.lnks-list li a,
.btn-style2,
.layout2 .slick-prev, .layout2  .slick-next,
.blog-title a,
.post-optz li a,
.service-col,
.layout3 .slick-prev,
.layout3 .slick-next,
.app-btns li,
.svs-list li a,
.toggle-item > h2,
.toggle-item > h2:before,
.post-info > h3,
.tags-list li a,
.shop-img:before,
.add-cart,
.add-cart-btn,
.nav-tabs .nav-link,
.delete-item,
.menu-bar a span,
.scrollTop,
.ft-links li a,
.brw-info > a,
.lnk-default,
.next-btn,
.rm-btn,
.subs-text > form button,
.mobile-menu ul li a,
.feature-col:before,
.services_col {
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.feature-col {
	-webkit-transition: all 0.7s ease-in-out;
	-moz-transition: all 0.7s ease-in-out;
	-ms-transition: all 0.7s ease-in-out;
	-o-transition: all 0.7s ease-in-out;
	transition: all 0.7s ease-in-out;
}

.rm-btn,
.tp-icon,
.slick-prev,
.slick-next,
.next-btn,
.user-img:before,
.user-img img,
.client-img,
.client-img img,
.reay-row,
.fd-img,
.fd-img > img,
.page-link,
.page-item:first-child .page-link,
.page-item:last-child .page-link,
.ctt-icon,
.add-cart span,
.payment-options .shipp input[type="radio"] + label span,
.scrollTop {
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	-ms-border-radius: 100px;
	-o-border-radius: 100px;
	border-radius: 100px;
}

.svs-list li a,
.contact-col .form-roww .form-field textarea,
.shop-img:before,
.review-form .form-field textarea,
.header-content,
.feature-col,
.feature-col:before,
.case-info,
.fact-col,
.team-img > img,
.download-btns li:last-child a img,
.blog-item,
.blog-img > img,
.service-col,
.app-btns li,
.client-col,
.services_col {
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	border-radius: 10px;
}

.sec-title > h2:before,
.sec-title > h2:after,
.ftz-btn,
.get-text > a,
.lnk-default,
.pages-list,
.widget-search form input,
.tags-list li a,
.form-field input,
.form-field textarea,
.sort-by select,
.add-cart,
.nav-tabs .nav-link,
.coupan-col > input,
.coupan-col button,
.btn-default {
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	-o-border-radius: 30px;
	border-radius: 30px;
}

.form-row input,
.btn-style2,
.subs-text > form input,
.subs-text > form button,
.cm-page-content form input,
.form-row button {
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	-ms-border-radius: 50px;
	-o-border-radius: 50px;
	border-radius: 50px;
}

.container {
    max-width: 1200px!important;
}


.partner-col:focus{
	outline: none;
}