@font-face {
    font-family: 'Futura XBlk BT';
    src: url('FuturaBT-ExtraBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Futura';
    src: url('Futura-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Futura Hv BT';
    src: url('FuturaBT-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Futura BdCn BT';
    src: url('FuturaBT-BoldCondensed.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Futura Md BT';
    src: url('FuturaBT-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Futura Lt BT';
    src: url('FuturaBT-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Futura MdCn BT';
    src: url('FuturaBT-MediumCondensed.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Futura Md BT';
    src: url('FuturaBT-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Futura';
    src: url('Futura-Bold_1.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Futura Lt BT';
    src: url('FuturaBT-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Futura Bk BT';
    src: url('FuturaBT-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura Bk BT';
    src: url('FuturaBT-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Futura';
    src: url('Futura-Black-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Futura LtCn BT';
    src: url('FuturaBT-LightCondensed.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Futura Md BT';
    src: url('FuturaBT-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Futura XBlk BT';
    src: url('FuturaBT-ExtraBlack_1.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Futura Hv BT';
    src: url('FuturaBT-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Futura XBlkCnIt BT';
    src: url('FuturaBT-ExtraBlackCondItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Futura';
    src: url('Futura-CondensedLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

